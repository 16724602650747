import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env

const initialState = {
  wallet: {},





  globalPage : 1
}

export const walletSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    apiFailed: (state, action) => {
      state.errorApiData = action.payload;
    },
   
    createWallet : (state, action) => {
      state.successMsg = action.payload;
    },
   
    resetState : (state) => {
      state.successMsg = "";
      state.errorApiData = "";
      state.viewcoupon = "";
    },
    setGlobalPage : (state, action)=>{
      state.globalPage = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const {createWallet, apiFailed,resetState, setGlobalPage} = walletSlice.actions

export default walletSlice.reducer






export const addWallet = (values) => async dispatch => {
  try {
    console.log(values);
      const res = await Api('post', `${REACT_APP_API_URL}/wallet/addWallet`, values)
      dispatch(createWallet(res.data.message));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      } 
  }
}


export const clearData = () => dispatch => {
  // dispatch(walletEditMsg(""));
}

export const setPageeeeee = (page) => dispatch => {
  dispatch(setGlobalPage(page))
}