import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
 const { REACT_APP_API_URL } = process.env

const initialState = {
  value: 0,  
  managenotificationlists : [],
  errorMessage:"",
  successMsg:"",
  globalPage : 1
}

export const managenotificationRequestSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    apiFailed: (state, action) => {
      state.errorApiData = action.payload;
    },   
    allManageNotificationlistRequests : (state, action) => {
      state.managenotificationlists = action.payload;
    },
    setGlobalPage : (state, action)=>{
      state.globalPage = action.payload
    },
    updateManagerNotification : (state, action) => {
      state.successMsg = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const {apiFailed,allManageNotificationlistRequests,setGlobalPage,updateManagerNotification} = managenotificationRequestSlice.actions

export default managenotificationRequestSlice.reducer


export const getmanageNotificationRequests = (page,search) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/managenotif/manageNotificationlistRequests?search=${search}&page=${page}`);
      dispatch(allManageNotificationlistRequests(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }  
}
export const updateManageNotification = (values) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/managenotif/addManageNotifcation`,values)
      dispatch(updateManagerNotification(res.data.message));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      } 
  }
}

export const setPageeeeee = (page) => dispatch => {
  dispatch(setGlobalPage(page))
}
