import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
 const { REACT_APP_API_URL } = process.env

const initialState = {
  value: 0,
  viewenquiry:{},
  enquires : [],
  globalPage : 1,
  errorApiData : ""
}

export const enquirySlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    apiFailed: (state, action) => {
      state.errorApiData = action.payload;
    },
    allEnquiryRequests : (state, action) => {
      state.enquires = action.payload;
    },
    enquiryDetail : (state, action) => {
      state.viewenquiry = action.payload;
    },
    setGlobalPage : (state, action)=>{
      state.globalPage = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const {enquiryDetail,apiFailed,allEnquiryRequests,setGlobalPage } = enquirySlice.actions

export default enquirySlice.reducer

export const getEnquiryRequests = (page,search) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/enquiry/enquiryRequests?search=${search}&page=${page}`);
      dispatch(allEnquiryRequests(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }  
}

export const detailRequest = (id) => async dispatch => {
  try {
    console.log("idsss",id);
      const res = await Api('post', `${REACT_APP_API_URL}/enquiry/viewEnquiryRequest/`,{id:id});
      console.log("data",res.data);
      dispatch(enquiryDetail(res.data))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const setPageeeeee = (page) => dispatch => {
  dispatch(setGlobalPage(page))
}