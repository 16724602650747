import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env

const initialState = {
    report:[],
    vendorExportList:{},
    productImport_val:{},
  }
  
export const reportSlices = createSlice({
    name: 'reportSlice',
    initialState,
    reducers:{
            
        allVendorExcelRequest : (state, action) => {            
            state.vendorExportList = action.payload;
        },
        productImport_set : (state, action) => {            
            state.productImport_val = action.payload;
        },
        apiFailed: (state, action) => {
            state.errorApiData = action.payload;
        },
        resetState : (state) => {
            state.successMsg = "";
            state.deleteSuccess = false;
            state.vendorExportList = "";
            state.productImport_val = "";
           
        },
        allAssign : (state, action) => {
            state.vendors = action.payload;
        }, 
    }
})

export const {allVendorExcelRequest,productImport_set,apiFailed,resetState,allAssign} = reportSlices.actions
export default reportSlices.reducer

export const fetchReport = (values) => async dispatch => {
    try{
        const res = await Api('post', `${REACT_APP_API_URL}/report/get-report`, values)
        
         dispatch(allVendorExcelRequest(res.data));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
            
  
}
export const fetchSalesReport = (values) => async dispatch => {
    try{
        const res = await Api('post', `${REACT_APP_API_URL}/report/getSalesReport`, values)
        
         dispatch(allVendorExcelRequest(res.data));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
            
  
}

export const fetchUserInvoiceReport = (values) => async dispatch => {
    try{
        const res = await Api('post', `${REACT_APP_API_URL}/report/getUserInvoiceReport`, values)
        
         dispatch(allVendorExcelRequest(res.data));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}
export const fetchVendorInvoiceReport = (values) => async dispatch => {
    try{
        const res = await Api('post', `${REACT_APP_API_URL}/report/getVendorInvoiceReport`, values)
        
         dispatch(allVendorExcelRequest(res.data));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}
export const getLowStockApi = (values) => async dispatch => {
    try{
        const res = await Api('post', `${REACT_APP_API_URL}/report/getLowStock`, values)
        console.log('getLowStockApi_2');
         dispatch(allVendorExcelRequest(res.data));
         dispatch(resetState())
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
            
  
}




export const getVendorList = () => async dispatch => {
    try {                  
        const res = await Api('post', `${REACT_APP_API_URL}/vendorpayment/get-vendor`);
        console.log("===============RES=======",res.data.data);
        dispatch(allAssign(res.data.data));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
  }

export const getProductExport = (values) => async dispatch => {
    try{
        const res = await Api('post', `${REACT_APP_API_URL}/product/productExport`, values)
         dispatch(allVendorExcelRequest(res.data));
         dispatch(resetState())
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
            
  
}

export const importExcleProduct = (values) => async dispatch => {
    try{
        const res = await Api('post', `${REACT_APP_API_URL}/product/productImport`, values)
        dispatch(productImport_set(res.data));
        dispatch(resetState())
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
            
  
}