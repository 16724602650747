import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
 const { REACT_APP_API_URL } = process.env

const initialState = {
  value: 0,
  vendor: {},
  viewvendor:{},
  vendors : [],
  companies : [],
  vendorEdit:{},
  vendorEditMsg : "",
  vendordeleteSuccess : false,
  errorMessage:"",
  successMsg:"",
  emailExist:"",
  globalPage : 1
}

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
    loginSuccess: (state, action) => {
      state.vendor = action.payload
    },
    allCompanies : (state, action) => {
      state.companies = action.payload;
    },
    apiFailed: (state, action) => {
      state.errorApiData = action.payload;
    },
    logoutSuccess: (state, action) => {
      state.vendor = {};
    },
    logoutFailed: (state, action) => {
      state.errorMessage = action.payload;
    },
    adminProfile: (state, action) => {
      state.profile = action.payload;
    },
    allVendors : (state, action) => {
      state.vendors = action.payload;
    },
    statusVendor : (state, action) => {
      state.vendor = action.payload;
    },
    vendorDetail : (state, action) => {
      state.viewvendor = action.payload;
    },
    vendorEdit : (state, action) => {
      state.vendorEdit = action.payload;
    },
    vendorEditMsg : (state, action) => {
      state.vendorEditMsg = action.payload;
    },
    vendorEmail : (state, action) => {
      state.emailExist = action.payload;
    },
    createVendor : (state, action) => {
      state.successMsg = action.payload;
    },
    vendorDelete : (state) => {
      state.vendordeleteSuccess = true;
    },
    setGlobalPage : (state, action)=>{
      state.globalPage = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement, vendorEditMsg,vendorEdit,statusVendor,vendorDetail,incrementByAmount, loginSuccess, vendorDelete,allCompanies,logoutSuccess, adminProfile, apiFailed,allVendors,createVendor,vendorEmail, setGlobalPage } = counterSlice.actions

export default counterSlice.reducer

export const getCompanies = (value) => async dispatch => {
  try {        
    console.log("value",value);   
      const res = await Api('post', `${REACT_APP_API_URL}/vendor/getListCompany`, {type:value});
      
      dispatch(allCompanies(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }

  
}

export const getVendors = (page,search) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/vendor/list?search=${search}&page=${page}`);
      dispatch(allVendors(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }  
}


export const addVendor = (values) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/vendor/addVendor`, values)
      if(res.data.status == 210){
        console.log("message",res.data.message);
        dispatch(vendorEmail(res.data.message))
      }else{
        dispatch(createVendor(res.data.message));
      }
      
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const updateVendor = (values) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/vendor/updateVendor`,values)
      
      dispatch(vendorEditMsg("Successfully update"))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const detailVendor = (vendor_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/vendor/viewVendor/${vendor_id}`);
      dispatch(vendorDetail(res.data))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const deleteVendor = (vendor_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/vendor/deleteVendor/`,{vendorId : vendor_id})
      dispatch(vendorDelete());
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}


export const statusChanageVendor = (statusData) => async dispatch => {
  try{

      const res = await Api('post',`${REACT_APP_API_URL}/vendor/status`,statusData);
      dispatch(statusVendor());
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}

export const checkCompany = (data) => async dispatch => {
  try {
    
      const res = await Api('post', `${REACT_APP_API_URL}/vendor/getListCompany`, data);
      console.log("slice data", res.data.data)
      dispatch(allCompanies(res.data.data))
      
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const clearData = () => dispatch => {
  dispatch(vendorEditMsg(""));
  dispatch(vendorEmail(""));
  dispatch(createVendor(""));
}

export const setPageeeeee = (page) => dispatch => {
  dispatch(setGlobalPage(page))
}