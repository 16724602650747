import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
 const { REACT_APP_API_URL } = process.env

const initialState = {
  value: 0,
  user: {},
  viewwishlist:{},
  wishlists : [],
  statusMsg : "",
  userdeleteSuccess : false,
  errorMessage:"",
  successMsg:""
}

export const vendorWishlistRequestSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    apiFailed: (state, action) => {
      state.errorApiData = action.payload;
    },
    allWishlistRequests : (state, action) => {
      state.wishlists = action.payload;
    },
    wishlistStatus : (state, action) => {
      state.successMsg = action.payload;
    },
    updateQuantityMsg : (state, action) => {
      state.statusMsg = action.payload;
    },
    wishlistDetail : (state, action) => {
      state.viewwishlist = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const {wishlistStatus,wishlistDetail,apiFailed,allWishlistRequests,updateQuantityMsg } = vendorWishlistRequestSlice.actions

export default vendorWishlistRequestSlice.reducer

export const getWishlistRequests = (page,vendor_id,search) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/vendor/wishlist/wishlistRequests/${vendor_id}/?page=${page}&search=${search}`);
      dispatch(allWishlistRequests(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }  
}

export const detailRequest = (id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/vendor/wishlist/viewWishlistRequest/${id}`);
      dispatch(wishlistDetail(res.data))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const updateQuantity = (formData) => async dispatch => {
  try{
      const res = await Api('post',`${REACT_APP_API_URL}/wishlist/updateQuantity`,formData);
      dispatch(updateQuantityMsg(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}

export const wishlistStatusChange = (statusData) => async dispatch => {
  try{
      const res = await Api('post',`${REACT_APP_API_URL}/wishlist/status`,{id:statusData});
      dispatch(wishlistStatus(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}

export const sendEmail = (declineData) => async dispatch => {
  try{
      const res = await Api('post',`${REACT_APP_API_URL}/wishlist/sendmail`,declineData);
      dispatch(wishlistStatus(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}

export const clearData = () => dispatch => {
  dispatch(wishlistStatus(""));
}