import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env

const initialState = {
  value: 0,
  banner: {},
  viewbanner:{},
  banners : [],
  bannerEdit:{},
  bannerEditMsg : "",
  bannerdeleteSuccess : false,
}

export const bannerSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
    apiFailed: (state, action) => {
      state.errorApiData = action.payload;
    },
    allBanners : (state, action) => {
      state.banners = action.payload;
    },
    createBanner : (state, action) => {
      state.successMsg = action.payload;
    },
    bannerDetail : (state, action) => {
      state.viewbanner = action.payload;
    },
    bannerEdit : (state, action) => {
      state.bannerEdit = action.payload;
    },
    bannerEditMsg : (state, action) => {
      state.bannerEditMsg = action.payload;
    },
    bannerDelete : (state) => {
      state.bannerdeleteSuccess = true;
    },
    resetState : (state) => {
      state.successMsg = "";
      state.bannerdeleteSuccess = false;
      state.errorApiData = "";
      state.viewbanner = "";
    },
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement, bannerEditMsg,bannerEdit,bannerDetail,createBanner,incrementByAmount, bannerDelete, apiFailed,allBanners,resetState } = bannerSlice.actions

export default bannerSlice.reducer


export const getBanners = (page) => async dispatch => {
  try {           
      const res = await Api('post', `${REACT_APP_API_URL}/banner/getBanners?page=${page}`);
      dispatch(allBanners(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const addBanner = (values) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/banner/addBanner`, values)
      dispatch(createBanner(res.data.message));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      } 
  }
}

// export const editCompany = (company_id) => async dispatch => {
//   try {
//       const res = await Api('post', `${REACT_APP_API_URL}/company/updateCustomer/${company_id}`)
//       dispatch(companyEdit(res.data))
//   } catch (e) {
//       if (e.response) {
//           dispatch(apiFailed(e.response.data))
//       } else {
//           dispatch(apiFailed(e.message))
//       }
//   }
// }

export const updateBanner = (banner_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/banner/updateBanner`,banner_id)
      dispatch(bannerEditMsg("Banner updated successfully"))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const detailBanner = (banner_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/banner/viewBanner/`,{bannerId:banner_id});
      dispatch(bannerDetail(res.data))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const deleteBanner = (banner_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/banner/deleteBanner`,{bannerId : banner_id})
      dispatch(bannerDelete());
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}


export const clearData = () => dispatch => {
  dispatch(bannerEditMsg(""));
}