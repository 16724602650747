import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
 const { REACT_APP_API_URL } = process.env

const initialState = {
  value: 0,
  user: {},
  viewuser:{},
  viewAddress:{},
  users : [],
  userEdit:{},
  userEditMsg : "",
  deleteSuccess : false,
  errorMessage:"",
  successMsg:"",
  error_type:'',
  globalPage : 1,
  updateLocationMsg: "",
  getLocationData: {},
  locationList: {}
}

export const salesPersonSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
  
    apiFailed: (state, action) => {
      state.errorApiData = action.payload;
    },
    
    userEditMsg : (state, action) => {
      state.userEditMsg = action.payload;
    },
    allSalesPerson : (state, action) => {
      state.users = action.payload;
    },
    statusUser : (state, action) => {
      state.user = action.payload;
    },
    salesPersonDetail : (state, action) => {
      state.viewuser = action.payload;
    },
    createUser : (state, action) => {
      state.successMsg = action.payload;
    },
   
    resetState : (state) => {
      state.successMsg = "";
      state.deleteSuccess = false;
    },
    setGlobalPage : (state, action)=>{
      state.globalPage = action.payload
    },
    errorType : (state, action) => {
      state.error_type = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { statusUser, apiFailed,allSalesPerson,errorType,createUser,salesPersonDetail,userEditMsg,resetState,salesPersonDelete, setGlobalPage} = salesPersonSlice.actions

export default salesPersonSlice.reducer

export const getSalesPerson = (page,search,filter = []) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/salesPerson/getSalesPerson?search=${search}&page=${page}`,filter);
      dispatch(allSalesPerson(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }  
}


export const addSalesPerson = (values) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/salesPerson/addSalesPerson`, values)
      dispatch(createUser(res.data.message));
  } catch (e) {
      if (e.response) {
          dispatch(errorType(e.response.data.type));
          dispatch(apiFailed(e.response.data.message))
        
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}
export const detailSalesPerson = (user_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/salesPerson/getSalesPersonByID/${user_id}`);
      dispatch(salesPersonDetail(res.data))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}
export const updateSalesPerson = (values) => async dispatch => {
  try {
    const res = await Api('post', `${REACT_APP_API_URL}/salesPerson/updateSalesPerson`, values)
    // dispatch(createUser(res.data.message));
    dispatch(userEditMsg("Successfully update"))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}
export const clearData = () => dispatch => {
  dispatch(userEditMsg(""));
 
}
export const statusChanageUser = (statusData) => async dispatch => {
  try{
      const res = await Api('post',`${REACT_APP_API_URL}/salesPerson/status`,statusData);
      dispatch(statusUser());
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
        
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}
export const deleteSalesPerson = (user_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/salesPerson/delete/`,{userId : user_id})
      dispatch(salesPersonDelete());
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const setPageeeeee = (page) => dispatch => {
  dispatch(setGlobalPage(page))
}