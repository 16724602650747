import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env

const initialState = {
    orders : [],
    order : {},
    totalpacket:{},
    status : {},
    statusChanged : {},
    updatePacked : {},
    globalPage : 1,
    drivers:[],
    assigneddrivers:{},
    assignSlot:[],
    location:[],
    locationList: {},
    successMsg: '',
  }

  export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
      apiFailed: (state, action) => {
        state.errorApiData = action.payload;
      },
      allOrders : (state, action) => {
        state.orders = action.payload;
      },
      orderDetail : (state, action) => {
        state.order = action.payload;
      },
      getAssignDriverByOrder : (state, action) => {
        state.assigneddrivers = action.payload;
      },
      locationDetail : (state, action) => {
        state.location = action.payload;
      },
      statusDetail : (state, action) => {
        state.status = action.payload;
      },
      statusChange : (state, action) => {
        state.statusChanged = action.payload;
      },
      updatePacket : (state, action) => {
        state.updatePacked = action.payload;
      },
      totalPacket : (state, action) => {
        state.totalpacket = action.payload;
      },
      allAssign : (state, action) => {
        state.drivers = action.payload;
      },
      allSlotData : (state, action) => {
        state.assignSlot = action.payload;
      },
      createAssign : (state, action) => {
        state.successMsg = action.payload;
      },
      setGlobalPage : (state, action)=>{
        state.globalPage = action.payload
      },
      addressList_ : (state, action) => {
        state.locationList = action.payload;
      },
    },
  })

  export const { apiFailed,allOrders,orderDetail,statusDetail,statusChange,totalPacket,updatePacket,setGlobalPage,allAssign,allSlotData,locationDetail,createAssign,getAssignDriverByOrder,addressList_} = orderSlice.actions

  export default orderSlice.reducer

  export const getOrders = (page,search,filter=[]) => async dispatch => {
    try {           
        const res = await Api('post', `${REACT_APP_API_URL}/order/all-orders?search=${search}&page=${page}`,filter);
        console.log(filter,'filter');
        dispatch(allOrders(res.data.data));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
  }

  export const getOrderDetail = (orderDetails) => async dispatch => {
    try {           
        const res = await Api('post', `${REACT_APP_API_URL}/order/order-detail`,orderDetails);
        dispatch(orderDetail(res.data.data));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
  }
  export const getLocationDetail = (locationDetails) => async dispatch => {
    try {           
        const res = await Api('post', `${REACT_APP_API_URL}/order/location-detail`,locationDetails);
        dispatch(locationDetail(res.data.data));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
  }

  

  export const getTotalPacket = (orderDetails) => async dispatch => {
    try {           
        const res = await Api('post', `${REACT_APP_API_URL}/order/order-packet`,orderDetails);
        dispatch(totalPacket(res.data.data));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
  }

  export const getStatusDetail = (status) => async dispatch => {
    try {           
        const res = await Api('post', `${REACT_APP_API_URL}/order/next-status`,status);
        dispatch(statusDetail(res.data.data));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
  }

  export const statusChanged = (status) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/order/status-update`,status);
        dispatch(statusChange(res.data));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
  }
  export const updatePacked = (nopacket) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/order/packet-update`,nopacket);
        dispatch(updatePacket(res.data));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
  }

  export const getDriverList = (date,location_id) => async dispatch => {
    try {    
        var driverAssign = {          
          driver_date: date,
          location_id: location_id,

        };       
        const res = await Api('post', `${REACT_APP_API_URL}/order/get-driver`,driverAssign);
        console.log("===============RES=======",res);
        dispatch(allAssign(res.data.data));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}  


export const getAssignDriverByOrder_id = (order_id) => async dispatch => {
  try {           
      const res = await Api('post', `${REACT_APP_API_URL}/order/get-assign-driver-order`,order_id);
      dispatch(getAssignDriverByOrder(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}
export const getSlotData = (driverDetails) => async dispatch => {  
  try{      
      const res = await Api('post', `${REACT_APP_API_URL}/order/get-SlotData`,driverDetails);  
      //const res = await Api('post', `${REACT_APP_API_URL}/order/get-SlotData/${driver_id}`);     
      dispatch(allSlotData(res.data.data));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}
export const updateDrivers = (values) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/order/order-Assign`,values)
      dispatch(createAssign(res.data.message));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      } 
  }
}
export const locationList = () => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/user/getLocationList/`)
      dispatch(addressList_(res.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}
export const clearData = () => dispatch => {
  dispatch(createAssign(""));
}

  export const setPageeeeee = (page) => dispatch => {
    dispatch(setGlobalPage(page))
  }