import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
 const { REACT_APP_API_URL } = process.env

const initialState = {
  globalPage : 1,
  subadmins : [],
  subadmin: {},
  subadminEditMsg : "",
  statusSuccess : false,
  globalPage : 1,
  successMsg: '',

}

export const subadminSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    apiFailed: (state, action) => {
      state.errorApiData = action.payload;
    },
    allSubAdmins : (state, action) => {
      state.subadmins = action.payload;
    },
    setGlobalPage : (state, action)=>{
      state.globalPage = action.payload
    },
    statusSubadmin : (state, action) => {
      state.successMsg = action.payload;
    },
    subadminEmail : (state, action) => {
      state.emailExist = action.payload;
    },
    subadminDetail : (state, action) => {
      state.viewsubadmin = action.payload;
    },
    createSubadmin : (state, action) => {
      state.successMsg = action.payload;
    },
    subadminEditMsg : (state, action) => {
      state.subadminEditMsg = action.payload;
    },
    resetState : (state) => {
      state.successMsg = "";      
      state.errorApiData = "";      
      state.subadmin = "";
      state.subadmins = "";
  },
  },
})

// Action creators are generated for each case reducer function
export const { apiFailed,allSubAdmins,setGlobalPage,statusSubadmin,subadminEmail,createSubadmin,subadminDetail,subadminEditMsg,resetState} = subadminSlice.actions

export default subadminSlice.reducer


export const getLists = (page,search) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/subadmin/list?search=${search}&page=${page}`);
      dispatch(allSubAdmins(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }  
}


export const statusChanageSubadmin = (statusData) => async dispatch => {
  try{

      const res = await Api('post',`${REACT_APP_API_URL}/subadmin/status`,statusData);
      dispatch(statusSubadmin());
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}  

export const setPageeeeee = (page) => dispatch => {
  dispatch(setGlobalPage(page))
}

export const addSubadmin = (values) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/subadmin/addSubadmin`, values)
      if(res.data.status == 210){
        console.log("message",res.data.message);
        dispatch(subadminEmail(res.data.message))
      }else{
        dispatch(createSubadmin(res.data.message));
      }
      
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const clearData = () => dispatch => {
  dispatch(subadminEditMsg(""));
  dispatch(subadminEmail(""));
  dispatch(createSubadmin(""));
}

export const detailSubadmin = (subadmin_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/subadmin/viewSubadmin/${subadmin_id}`);
      dispatch(subadminDetail(res.data))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const updateSubadmin = (values) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/subadmin/updateSubadmin`,values)      
      dispatch(subadminEditMsg("Successfully update"))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}


