import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env

const initialState = {
  slot: {},
  viewslot:{},
  slots : [],
  slotEdit:{},
  slotEditMsg : "",
  slotdeleteSuccess : false,
}

export const timeSlotSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    apiFailed: (state, action) => {
      state.errorApiData = action.payload;
    },
    allSlots : (state, action) => {
      state.slots = action.payload;
    },
    createSlot : (state, action) => {
      state.successMsg = action.payload;
    },
    slotDetail : (state, action) => {
      state.viewslot = action.payload;
    },
    slotEdit : (state, action) => {
      state.slotEdit = action.payload;
    },
    slotEditMsg : (state, action) => {
      state.successMsg = action.payload;
    },
    slotUpdateStatus : (state, action) => {
      state.successMsg = action.payload;
    },
    slotDelete : (state) => {
      state.slotdeleteSuccess = true;
    },
    resetState : (state) => {
      state.successMsg = "";
      state.errorApiData = "";
      state.viewslot = "";
      state.slotdeleteSuccess = false;
    },
  },
})

// Action creators are generated for each case reducer function
export const { slotEditMsg,slotEdit,slotDetail,createSlot, brandDelete, apiFailed,allSlots,resetState,slotUpdateStatus, slotDelete } = timeSlotSlice.actions

export default timeSlotSlice.reducer


export const getSlots = (page) => async dispatch => {
  try {           
      const res = await Api('post', `${REACT_APP_API_URL}/time/getSlots?page=${page}`);
      dispatch(allSlots(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const addSlot = (values) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/time/addSlot`, values)
      dispatch(createSlot(res.data.message));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      } 
  }
}

export const updateSlot = (slot_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/time/updateSlot`,slot_id)
      console.log("res",res.data.message);
      dispatch(slotEditMsg(res.data.message))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const detailSlot = (slot_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/time/viewSlot/`,{slot_id:slot_id});
      dispatch(slotDetail(res.data))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const deleteSlot = (slot_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/time/deleteSlot`,{slotId : slot_id})
      dispatch(slotDelete());
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const statusChangeSlot = (value) => async dispatch => {
  try{
      const res = await Api('post', `${REACT_APP_API_URL}/time/updateSlotStatus`, value)
      dispatch(slotUpdateStatus(res.data.message))
  }catch(e){
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const clearData = () => dispatch => {
  dispatch(slotEditMsg(""));
}