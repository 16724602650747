import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices' 
 const { REACT_APP_API_URL,REACT_APP_DRIVERAPP_API_URL } = process.env

const initialState = {
  value: 0,
  statusMsg : "",
  driverrequests : [],
  errorMessage:"",
  successMsg:"",
  viewdriver:{},
}

export const driverRequestSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    resetState : (state) => {
      state.statusMsg = "";
    },
    allDriverRequests : (state, action) => {
      state.driverrequests = action.payload;
    },
    driverRequestStatus : (state, action) => {
      state.statusMsg = action.payload;
    },
    apiFailed: (state, action) => {
      state.errorApiData = action.payload;
    },
    driverDetail : (state, action) => {
      state.viewdriver = action.payload;
    },
    
  },
})

// Action creators are generated for each case reducer function
export const {resetState,allDriverRequests, driverRequestStatus, apiFailed,driverDetail} = driverRequestSlice.actions

export default driverRequestSlice.reducer

export const getDriverRequests = (page) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_DRIVERAPP_API_URL}/request/driverrequestlist?page=${page}`);
      dispatch(allDriverRequests(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }  
}

export const driverStatusChange = (statusData) => async dispatch => {  
  try{
      const res = await Api('post',`${REACT_APP_DRIVERAPP_API_URL}/request/driverstatus`,{id:statusData});
      console.log("response",res.data.message)
      dispatch(driverRequestStatus(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}
export const driverdetailRequest = (driver_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_DRIVERAPP_API_URL}/request/driverviewRequest/${driver_id}`);
      dispatch(driverDetail(res.data))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const sendEmail = (statusData) => async dispatch => {
  try{
      const res = await Api('post',`${REACT_APP_DRIVERAPP_API_URL}/request/sendNotVerifyEmail`,{data:statusData});
      //console.log("response",res.data.message)
      dispatch(driverRequestStatus(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
} 

 /* export const detailRequest = (user_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/user/viewRequest/${user_id}`);
      dispatch(driverDetail(res.data))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
} */
/*
export const clearData = () => dispatch => {
  dispatch(kycRequestStatus(""));
}  */