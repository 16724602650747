import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env

export const editProfileSlice = createSlice({
    name: 'changePassword',
    initialState:{
        errorApiData: '',
        viewvendor:{},
        vendorProfileMsg : "",
    },
    reducers: {
        apiFailed: (state, action) => {
            state.errorApiData = action.payload;
        },
        vendorDetail : (state, action) => {
            state.viewvendor = action.payload;
        },
        vendorEditMsg : (state, action) => {
            state.vendorProfileMsg = action.payload;
        },
        resetState : (state) => {
            state.vendorProfileMsg = "";
            state.errorApiData = "";
        },
    },
});

// Action creators are generated for each case reducer function
export const {apiFailed,resetState,vendorDetail,vendorEditMsg } = editProfileSlice.actions
export default editProfileSlice.reducer

export const detailVendor = (vendor_id) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/vendor/profile/viewVendor/${vendor_id}`);
        dispatch(vendorDetail(res.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const updateVendor = (values) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/vendor/profile/updateVendor`,values)
        dispatch(vendorEditMsg(res.data.message))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data.message))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}
  

export const clearData = () => dispatch => {
    dispatch(vendorEditMsg(""));
}