import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL ,REACT_APP_DRIVERAPP_API_URL} = process.env

export const scheduleSlice = createSlice({
    name: 'schedule',
    initialState:{
        errorApiData: '',
        schedulees : [],
       // scheduleEditMsg:"",
        errorMessage: '',
        successMsg: '',
        deleteSuccess : false,
        statusSuccess : false, 
        drivers : [],  
        drivercycle : [],         
        driverslot : [], 
        driverlocation:[], 
        viewschedule:{}, 
        scheduleEditMsg:"",
        globalPage : 1
    },
    reducers: {
        apiFailed: (state, action) => {
            state.errorApiData = action.payload;
        },
        allschedulees : (state, action) => {
            state.schedulees = action.payload;
        },
        createschedule : (state, action) => {
            state.successMsg = action.payload;
          },
        allDrivers : (state, action) => {
            state.drivers = action.payload;
        }, 
        allCycles : (state, action) => {
            state.drivercycle = action.payload;
        }, 
        allSlots : (state, action) => {
            state.driverslot = action.payload;
        },  
        allLocation : (state, action) => {
            state.driverlocation = action.payload;
        },
        setscheduleDetail : (state, action) => {
            state.viewschedule = action.payload;
        }, 
        scheduleEditMsg : (state, action) => {
            state.scheduleEditMsg = action.payload;
          }, 
        scheduleDelete : (state) => {
            state.deleteSuccess = true;
        }, 
        resetState : (state) => {            
            state.deleteSuccess = false;
            state.errorApiData = "";  
            state.schedulees  = []; 
            state.successMsg = '';       
          }, 
        setGlobalPage : (state, action)=>{
            state.globalPage = action.payload
          }    
    },
});

// Action creators are generated for each case reducer function
export const {apiFailed,allschedulees,createschedule,allDrivers,allCycles,allSlots,allLocation,setscheduleDetail,scheduleEditMsg,scheduleDelete,resetState,setGlobalPage} = scheduleSlice.actions
export default scheduleSlice.reducer

export const getschedule = (page) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_DRIVERAPP_API_URL}/schedule/scheduleList?page=${page}`)
        dispatch(allschedulees(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}
export const addSchedule = (values) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_DRIVERAPP_API_URL}/schedule/scheduleAdd`, values)
        dispatch(createschedule(res.data.message));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data.message))
        } else {
            dispatch(apiFailed(e.message))
        } 
    }
  }

export const getDrivers = () => async dispatch => {
    try {    
        console.log("=============TEST===================")       
        const res = await Api('post', `${REACT_APP_DRIVERAPP_API_URL}/schedule/getDrivers`);
        dispatch(allDrivers(res.data.data));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}  

export const getCycle = (driver_id) => async dispatch => {
    try {           
        const res = await Api('post', `${REACT_APP_DRIVERAPP_API_URL}/schedule/getListCycle/${driver_id}`);
        dispatch(allCycles(res.data.data));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}
export const getSlots = (cycleData) => async dispatch => {  
    try{
        const res = await Api('post',`${REACT_APP_DRIVERAPP_API_URL}/schedule/getListSlots`,cycleData);        
        dispatch(allSlots(res.data.data));
    }catch(e){
        if(e.response){
            dispatch(apiFailed(e.response.data.message));
        }else{
            dispatch(apiFailed(e.message));
        }
    }
  }
export const getLocation = () => async dispatch => {  
    try{
        const res = await Api('post',`${REACT_APP_DRIVERAPP_API_URL}/schedule/getLocation`);        
        dispatch(allLocation(res.data.data));
    }catch(e){
        if(e.response){
            dispatch(apiFailed(e.response.data.message));
        }else{
            dispatch(apiFailed(e.message));
        }
    }
  } 

  export const detailSchedule = (schedule_id) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_DRIVERAPP_API_URL}/schedule/detail`,{
            schedule_id:schedule_id
        });
        dispatch(setscheduleDetail(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
  }

  export const clearData = () => dispatch => {
    dispatch(scheduleEditMsg(""));
  } 
 
  export const deleteschedule = (schedule_id) => async dispatch =>{
    try {
        const res = await Api('post',`${REACT_APP_DRIVERAPP_API_URL}/schedule/deleteschedule`,{scheduleId : schedule_id})
        dispatch(scheduleDelete());
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
  }
  

export const editSchedule = (schedule) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_DRIVERAPP_API_URL}/schedule/update`,schedule);
        dispatch(scheduleEditMsg(res.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}
export const setPageeeeee = (page) => dispatch => {
    dispatch(setGlobalPage(page))
  }

 