import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
 const { REACT_APP_API_URL } = process.env

const initialState = {
  value: 0,
  user: {},
  viewexchangelist:{},
  exchangelists : [],
  statusMsg : "",
  userdeleteSuccess : false,
  errorMessage:"",
  successMsg:"",
  globalPage : 1
}

export const exchangeRequestSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    apiFailed: (state, action) => {
      state.errorApiData = action.payload;
    },
    allExchangelistRequests : (state, action) => {
      state.exchangelists = action.payload;
    },
    exchangelistStatus : (state, action) => {
      state.successMsg = action.payload;
    },
    updateDetailMsg : (state, action) => {
      state.statusMsg = action.payload;
    },
    exchangelistDetail : (state, action) => {
      state.viewexchangelist = action.payload;
    },
    setGlobalPage : (state, action)=>{
      state.globalPage = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const {exchangelistStatus,exchangelistDetail,apiFailed,allExchangelistRequests,updateDetailMsg,setGlobalPage } = exchangeRequestSlice.actions

export default exchangeRequestSlice.reducer

export const getExchangeRequests = (page,search) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/express/exchangelistRequests?search=${search}&page=${page}`);
      dispatch(allExchangelistRequests(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }  
}

export const sendEmail = (declineData) => async dispatch => {
  try{
      const res = await Api('post',`${REACT_APP_API_URL}/express/sendmail`,declineData);
      dispatch(exchangelistStatus(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}

export const detailRequest = (id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/express/viewexchangelistRequest/${id}`);
      dispatch(exchangelistDetail(res.data))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const updateDetails = (formData) => async dispatch => {
  try{
      const res = await Api('post',`${REACT_APP_API_URL}/express/updateDetails`,formData);
      dispatch(updateDetailMsg(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}

export const exchangelistStatusChange = (statusData) => async dispatch => {
  try{
      const res = await Api('post',`${REACT_APP_API_URL}/exchangelist/status`,{id:statusData});
      dispatch(exchangelistStatus(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}



export const exchangelistStatusCancel = (statusData) => async dispatch => {
  try{
    //console.log("statusData",statusData);
      const res = await Api('post',`${REACT_APP_API_URL}/exchangelist/cancelStatus`,{id:statusData});
      dispatch(exchangelistStatus(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}

export const clearData = () => dispatch => {
  dispatch(exchangelistStatus(""));
}

export const setPageeeeee = (page) => dispatch => {
  dispatch(setGlobalPage(page))
}