import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
 const { REACT_APP_API_URL } = process.env

const initialState = {
  value: 0,
  user: {},
  viewuser:{},
  viewAddress:{},
  users : [],
  userEdit:{},
  userEditMsg : "",
  deleteSuccess : false,
  errorMessage:"",
  successMsg:"",
  globalPage : 1,
  updateLocationMsg: "",
  getLocationData: {},
  locationList: {}
}

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
    loginSuccess: (state, action) => {
      state.user = action.payload
    },
    apiFailed: (state, action) => {
      state.errorApiData = action.payload;
    },
    logoutSuccess: (state, action) => {
      state.user = {};
    },
    logoutFailed: (state, action) => {
      state.errorMessage = action.payload;
    },
    adminProfile: (state, action) => {
      state.profile = action.payload;
    },
    allUsers : (state, action) => {
      state.users = action.payload;
    },
    statusUser : (state, action) => {
      state.user = action.payload;
    },
    userDetail : (state, action) => {
      state.viewuser = action.payload;
    },
    userEdit : (state, action) => {
      state.userEdit = action.payload;
    },
    userEditMsg : (state, action) => {
      state.userEditMsg = action.payload;
    },
    createUser : (state, action) => {
      state.successMsg = action.payload;
    },
    userDelete : (state) => {
      state.deleteSuccess = true;
    },
    resetState : (state) => {
      state.successMsg = "";
      state.deleteSuccess = false;
    },
    setGlobalPage : (state, action)=>{
      state.globalPage = action.payload
    },
    getAddress_ : (state, action) => {
      state.viewAddress = action.payload;
    },
    getLocation_ : (state, action) => {
      state.getLocationData = action.payload;
    },
    updateLocationMsg : (state, action) => {
      state.updateLocationMsg = action.payload;
    },
    addressList_ : (state, action) => {
      state.locationList = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement, userEditMsg,userEdit,statusUser,userDetail,incrementByAmount, loginSuccess, userDelete,logoutSuccess, adminProfile, apiFailed,allUsers,createUser,resetState, setGlobalPage,getAddress_,getLocation_,updateLocationMsg,addressList_ } = counterSlice.actions

export default counterSlice.reducer

export const login = (value) => async dispatch => {
  try {
    const res = await Api('post', `${REACT_APP_API_URL}/auth/login`, value);
    if(res.data.statusCode === 200){
      dispatch(loginSuccess(res.data.data));
    }else{
      dispatch(loginSuccess(res.data.message));
    }
    
  } catch (e) {
    if (e.response) {
      dispatch(apiFailed(e.response.data))
    } else {
      dispatch(apiFailed(e.message))
    }
  }
}

export const submitforgetPass = (value) => async dispatch => {
  try {
    const res = await Api('post', `${REACT_APP_API_URL}/auth/forgetpassword`, value);
    if(res.data.statusCode === 200){
      dispatch(loginSuccess(res.data.data));
    }else{
      dispatch(loginSuccess(res.data.message));
    }
    
  } catch (e) {
    if (e.response) {
      dispatch(apiFailed(e.response.data))
    } else {
      dispatch(apiFailed(e.message))
    }
  }
}


export const authLogout = () => async dispatch => {
  try {
      dispatch(logoutSuccess());
      dispatch(adminProfile());
      dispatch(allUsers());
      dispatch(apiFailed())
  } catch (e) {
    if (e.response) {
      dispatch(apiFailed(e.response.data))
    } else {
      dispatch(apiFailed(e.message))
    }
  }
}
export const getProfile = () => async dispatch => {
  try {
    const res = await Api('post',`${REACT_APP_API_URL}/auth/profile`, );
    dispatch(adminProfile(res.data.data));
  } catch (e) {
    if (e.response) {
      dispatch(apiFailed(e.response.data))
    } else {
      dispatch(apiFailed(e.message))
    }
  }
}
// export const profileUpdate = (values) => async dispatch => {
//   try {
//       const res = await Api('put', `${REACT_APP_API_URL}/profile-update`, values);
//       if(res.status == 200){
//         dispatch(adminProfile(res.data.data));
//         dispatch(updateProfile(res));
//       }else{
//         dispatch(apiFailed(res.message))
//       }
//   } catch (e) {
//       if (e.response) {
//           dispatch(apiFailed(e.response.data))
//       } else {
//           dispatch(apiFailed(e.message))
//       }
//   }
// }

export const getUsers = (page,search) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/user/list?search=${search}&page=${page}`);
      dispatch(allUsers(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }  
}


export const addUser = (values) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/user/add-customer`, values)
      console.log("createUser",res.data.message)
      dispatch(createUser(res.data.message));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

// export const editUser = (user_id) => async dispatch => {
//   try {
//       const res = await Api('post', `${REACT_APP_API_URL}/user/updateCustomer/${user_id}`)
//       dispatch(userEdit(res.data))
//   } catch (e) {
//       if (e.response) {
//           dispatch(apiFailed(e.response.data))
//       } else {
//           dispatch(apiFailed(e.message))
//       }
//   }
// }

export const updateUser = (values) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/user/updateCustomer`,values)
      dispatch(userEditMsg("Successfully update"))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const detailUser = (user_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/user/viewCustomer/${user_id}`);
      dispatch(userDetail(res.data))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const deleteUser = (user_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/user/deleteCustomer/`,{userId : user_id})
      dispatch(userDelete());
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const getAddress = (user_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/user/getAddress/`,{userId : user_id})
      dispatch(getAddress_(res.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const getLocation = (id,user_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/user/getLocation/`,{id : id,user_id:user_id})
      dispatch(getLocation_(res.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const locationList = () => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/user/getLocationList/`)
      dispatch(addressList_(res.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const updateLocation = (values) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/user/updateLocation`,values)
      dispatch(updateLocationMsg("Successfully updated"))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}


export const statusChanageUser = (statusData) => async dispatch => {
  try{

      const res = await Api('post',`${REACT_APP_API_URL}/user/status`,statusData);
      dispatch(statusUser());
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}

export const clearData = () => dispatch => {
  dispatch(userEditMsg(""));
  dispatch(updateLocationMsg(""));
}

export const setPageeeeee = (page) => dispatch => {
  dispatch(setGlobalPage(page))
}