import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env

export const cmsSlice = createSlice({
    name: 'cms',
    initialState:{
        cmses : [],
        cmslist : [],
        viewCms:{},
        cmsEditMsg:"",
        errorApiData: '',
        errorMessage: '',
        successMsg: '',
        cmsById : {},
        cmsUpd : {},
        cmsData : {},
        cmsDelete:'',
        deleteSuccess : false,
        statusSuccess : false
    },
    reducers: {
        apiFailed: (state, action) => {
            state.errorApiData = action.payload;
        },
        allCmses : (state, action) => {
            state.cmses = action.payload;
        },
        CmsDetailData : (state, action) => {
            state.cmsData = action.payload;
        },
        setCmsDetail : (state, action) => {
            state.viewCms = action.payload;
          },
          cmsDelete : (state) => {
            state.deleteSuccess = true;
        },
        createCms : (state, action) => {
            state.successMsg = action.payload;
          },
        statusCms: (state) => {
            state.statusSuccess = true;
        },
        cmsUpdate : (state, action) => {
            state.successMsg = action.payload;
        },
        cmsEditMsg : (state, action) => {
            state.cmsEditMsg = action.payload;
          },
        resetState : (state) => {
            state.successMsg = "";
            state.deleteSuccess = false;
            state.errorApiData = "";
            state.statusSuccess = false;
            state.createSuccessMsg = "";
            state.cmsById = "";
        },
    },
});

// Action creators are generated for each case reducer function
export const {resetState, apiFailed,allCmses,CmsDetailData,createCms,cmsEditMsg,cmsUpdate,setCmsDetail,cmsDelete,deleteSuccess } = cmsSlice.actions
export default cmsSlice.reducer

export const getCms = (page) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/cms/list?page=${page}`)
        dispatch(allCmses(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const editCms = (cms) => async dispatch => {
    try {

      //  ${company_id.get("_id")}`,{category_name : company_id.get('category_name') });
        const res = await Api('post', `${REACT_APP_API_URL}/cms/update`,{
            cms_id:cms.get('_id'),
            title:cms.get('title'),
            content:cms.get('content'),
        });
        dispatch(cmsEditMsg(res.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const addCms = (values) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/cms/add`, values)
        dispatch(createCms(res.data.message));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data.message))
        } else {
            dispatch(apiFailed(e.message))
        } 
    }
  }

export const detailCms = (cms_id) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/cms/detail`,{
            cms_id:cms_id
        });
        dispatch(setCmsDetail(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
  }

  export const deleteCms = (cms_id) => async dispatch =>{
    try {
        const res = await Api('post',`${REACT_APP_API_URL}/cms/deleteCms`,{cmsId : cms_id})
        dispatch(cmsDelete());
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
  }
  export const clearData = () => dispatch => {
    dispatch(cmsEditMsg(""));
  }