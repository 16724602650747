import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
 const { REACT_APP_API_URL } = process.env

const initialState = {
  value: 0,
  user: {},
  viewcreditlist:{},
  creditlists : [],
  statusMsg : "",
  userdeleteSuccess : false,
  errorMessage:"",
  successMsg:"",
  globalPage : 1
}

export const creditlistRequestSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    apiFailed: (state, action) => {
      state.errorApiData = action.payload;
    },
    allcreditlistRequests : (state, action) => {
      state.creditlists = action.payload;
    },
    creditlistStatus : (state, action) => {
      state.successMsg = action.payload;
    },
    updateQuantityMsg : (state, action) => {
      state.statusMsg = action.payload;
    },
    creditlistDetail : (state, action) => {
      state.viewcreditlist = action.payload;
    },
    setGlobalPage : (state, action)=>{
      state.globalPage = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const {creditlistStatus,creditlistDetail,apiFailed,allcreditlistRequests,updateQuantityMsg,setGlobalPage } = creditlistRequestSlice.actions

export default creditlistRequestSlice.reducer

export const getCreditlistRequests = (page,search) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/wallet/creditlistRequests?search=${search}&page=${page}`);
      dispatch(allcreditlistRequests(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }  
}

export const detailRequest = (id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/wallet/viewcreditlistRequest/${id}`);
      dispatch(creditlistDetail(res.data))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const creditRequestStatus = (formData) => async dispatch => {
  try{
      const res = await Api('post',`${REACT_APP_API_URL}/wallet/creditRequestStatus`,formData);      
      dispatch(creditlistStatus(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}

/*export const updateQuantity = (formData) => async dispatch => {
  try{
      const res = await Api('post',`${REACT_APP_API_URL}/creditlist/updateQuantity`,formData);
      dispatch(updateQuantityMsg(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}*/

/*export const creditlistStatusChange = (statusData) => async dispatch => {
  try{
      const res = await Api('post',`${REACT_APP_API_URL}/creditlist/status`,{id:statusData});
      dispatch(creditlistStatus(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}*/

/*export const sendEmail = (declineData) => async dispatch => {
  try{
      const res = await Api('post',`${REACT_APP_API_URL}/creditlist/sendmail`,declineData);
      dispatch(creditlistStatus(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}*/

/*export const creditlistStatusCancel = (statusData) => async dispatch => {
  try{
    //console.log("statusData",statusData);
      const res = await Api('post',`${REACT_APP_API_URL}/creditlist/cancelStatus`,{id:statusData});
      dispatch(creditlistStatus(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}*/

export const clearData = () => dispatch => {
  dispatch(creditlistStatus(""));
}

export const setPageeeeee = (page) => dispatch => {
  dispatch(setGlobalPage(page))
}