import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
 const { REACT_APP_API_URL } = process.env

const initialState = {
  value: 0,
  user: {},
  viewcreditlist:{},
  creditmlists : [],
  statusMsg : "",
  userdeleteSuccess : false,
  errorMessage:"",
  successMsg:"",
  globalPage : 1
}

export const creditlistManagerSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    apiFailed: (state, action) => {
      state.errorApiData = action.payload;
    },
    allcreditlistManagers : (state, action) => {
      state.creditmlists = action.payload;
    },
    creditlistStatus : (state, action) => {
      state.successMsg = action.payload;
    },
    updateQuantityMsg : (state, action) => {
      state.statusMsg = action.payload;
    },
    creditlistDetail : (state, action) => {
      state.viewcreditlist = action.payload;
    },
    setGlobalPage : (state, action)=>{
      state.globalPage = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const {creditlistStatus,creditlistDetail,apiFailed,allcreditlistManagers,updateQuantityMsg,setGlobalPage } = creditlistManagerSlice.actions

export default creditlistManagerSlice.reducer

export const getCreditlistManagers = (page,search) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/wallet/creditlistManagers?search=${search}&page=${page}`);
      dispatch(allcreditlistManagers(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }  
}

export const clearData = () => dispatch => {
  dispatch(creditlistStatus(""));
}

export const setPageeeeee = (page) => dispatch => {
  dispatch(setGlobalPage(page))
}

export const sendEmail = (data) => async dispatch => {
  try{
      const res = await Api('post',`${REACT_APP_API_URL}/wallet/sendmail`,data);
      dispatch(creditlistStatus(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}