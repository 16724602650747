import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
 const { REACT_APP_API_URL } = process.env

const initialState = {
  errorMessage:"",
  dashboardData : [],
  successMsg:''
}

export const dashboardRequestSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    apiFailed: (state, action) => {
      state.errorApiData = action.payload;
    },
    allDashboardRequests : (state, action) => {
      state.dashboardData = action.payload;
      // state.successMsg = action.payload;
    }, 
    removeDriver_cron : (state, action) => {
      state.successMsg = action.payload;
    }, 
    resetState : (state) => {
      state.successMsg = "";
      state.deleteSuccess = false;
    },  
  },
})

// Action creators are generated for each case reducer function
export const {allDashboardRequests,resetState,removeDriver_cron,apiFailed} = dashboardRequestSlice.actions

export default dashboardRequestSlice.reducer



export const getdashboardRequests = () => async dispatch => {
  try {      
      const res = await Api('post', `${REACT_APP_API_URL}/dashboard/getDashboardData`);
      dispatch(allDashboardRequests(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }  
}



export const removeDriver = () => async dispatch => {
  try{
      const res = await Api('post', `${REACT_APP_API_URL}/order/orderAssignRemoveCron`,)
      dispatch(removeDriver_cron(res.data.message))
  }catch(e){
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}
