import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
 const { REACT_APP_API_URL,REACT_APP_DRIVERAPP_API_URL } = process.env

const initialState = {
  value: 0,
  driver:{},
  drivers : [],
  errorMessage:"",
  viewdriver:{},
  successMsg:"",
  globalPage : 1,
  driverEditMsg : "",
  deleteSuccess : false,
 
}

export const driverSlice = createSlice({
  name: 'driver',
  initialState,
  reducers: {   
    apiFailed: (state, action) => {
      state.errorApiData = action.payload;
    },
    allDrivers : (state, action) => {
      state.drivers = action.payload;
    },
    setGlobalPage : (state, action)=>{
      state.globalPage = action.payload
    },
    driverDetail : (state, action) => {
      state.viewdriver = action.payload;
    }, 
    createDriver : (state, action) => {
      state.successMsg = action.payload;
    },
    driverEditMsg : (state, action) => {
      state.driverEditMsg = action.payload;
    },
    statusDriver : (state, action) => {
      state.driver = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const {apiFailed,allDrivers,setGlobalPage,driverDetail,createDriver,driverEditMsg,statusDriver} = driverSlice.actions

export default driverSlice.reducer

export const getDrivers = (page,search) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_DRIVERAPP_API_URL}/user/list?search=${search}&page=${page}`);
      dispatch(allDrivers(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }  
}
export const detailDriver = (driver_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_DRIVERAPP_API_URL}/user/viewCustomer/${driver_id}`);
      dispatch(driverDetail(res.data))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const setPageeeeee = (page) => dispatch => {
  dispatch(setGlobalPage(page))
}


export const addDriver = (values) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_DRIVERAPP_API_URL}/user/addDriver`, values)      
      dispatch(createDriver(res.data.message));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const updateDriver = (values) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_DRIVERAPP_API_URL}/user/updateCustomer`,values)
      dispatch(driverEditMsg("Successfully update"))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const clearData = () => dispatch => {
  dispatch(driverEditMsg(""));
}

/* export const deleteUser = (user_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/user/deleteCustomer/`,{userId : user_id})
      dispatch(userDelete());
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
} */

export const statusChanageDriver = (statusData) => async dispatch => {  
  try{
      const res = await Api('post',`${REACT_APP_DRIVERAPP_API_URL}/user/status`,statusData);
      dispatch(statusDriver());
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}

/*  */

