import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env

export const variationSlice = createSlice({
    name: 'variation',
    initialState:{
        variations : [],
        variationlist : [],
        viewVariation:{},
        variationEditMsg:"",
        errorApiData: '',
        errorMessage: '',
        successMsg: '',
        variationById : {},
        variationUpd : {},
        variationData : {},
        variationDelete:'',
        deleteSuccess : false,
        statusSuccess : false,
        globalPage : 1
    },
    reducers: {
        apiFailed: (state, action) => {
            state.errorApiData = action.payload;
        },
        allVariationes : (state, action) => {
            state.variations = action.payload;
        },
        VariationDetailData : (state, action) => {
            state.variationData = action.payload;
        },
        createVariation : (state, action) => {
            state.successMsg = action.payload;
          },
        setVariationDetail : (state, action) => {
            state.viewVariation = action.payload;
          },
          variationDelete : (state) => {
            state.deleteSuccess = true;
        },
        statusVariation: (state) => {
            state.statusSuccess = true;
        },
        variationUpdate : (state, action) => {
            state.successMsg = action.payload;
        },
        variationEditMsg : (state, action) => {
            state.variationEditMsg = action.payload;
          },
        resetState : (state) => {
            state.successMsg = "";
            state.deleteSuccess = false;
            state.errorApiData = "";
            state.statusSuccess = false;
            state.createSuccessMsg = "";
            state.variationById = "";
        },
        setGlobalPage : (state, action)=>{
            state.globalPage = action.payload
        }
    },
});

// Action creators are generated for each case reducer function
export const {resetState, apiFailed,allVariationes,createVariation,VariationDetailData,variationEditMsg,variationUpdate,setVariationDetail,variationDelete,setGlobalPage } = variationSlice.actions
export default variationSlice.reducer

export const getVariation = (page,search) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/variation/variationList?search=${search}&page=${page}`)
        dispatch(allVariationes(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const addVariation = (values) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/variation/addVariation`, values)
        dispatch(createVariation(res.data.message));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data.message))
        } else {
            dispatch(apiFailed(e.message))
        } 
    }
  }

export const editVariation = (variation) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/variation/editVariation`,{
            variation_id:variation.get('_id'),
            type:variation.get('type'),
        });
        dispatch(variationEditMsg(res.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

// export const updateVariation = (value) => async dispatch => {
//     try{
//         const res = await Api('post', `${REACT_APP_API_URL}/variation/update`, value)
//         dispatch(variationUpdate(res.data.message))
//     }catch(e){
//         if (e.response) {
//             dispatch(apiFailed(e.response.data.message))
//         } else {
//             dispatch(apiFailed(e.message))
//         }
//     }
// }

 export const detailVariation = (variation_id) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/variation/variationDetail`,{
            variation_id:variation_id
        });
        dispatch(setVariationDetail(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
} 

export const clearData = () => dispatch => {
    dispatch(variationEditMsg(""));
}

export const setPageeeeee = (page) => dispatch => {
    dispatch(setGlobalPage(page))
}
