import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL ,REACT_APP_DRIVERAPP_API_URL} = process.env

export const locationSlice = createSlice({
    name: 'location',
    initialState:{
        locationes : [],
        locationlist : [],
        viewlocation:{},
        locationEditMsg:"",
        errorApiData: '',
        errorMessage: '',
        successMsg: '',
        locationById : {},
        locationUpd : {},
        locationData : {},
        locationDelete:'',
        deleteSuccess : false,
        statusSuccess : false,
        globalPage : 1       
    },
    reducers: {
        apiFailed: (state, action) => {
            state.errorApiData = action.payload;
        },
        alllocationes : (state, action) => {
            state.locationes = action.payload;
        },
        locationDetailData : (state, action) => {
            state.locationData = action.payload;
        },
        setlocationDetail : (state, action) => {
            state.viewlocation = action.payload;
          },
          locationDelete : (state) => {
            state.deleteSuccess = true;
        },
        createlocation : (state, action) => {
            state.successMsg = action.payload;
          },
        statuslocation: (state) => {
            state.statusSuccess = true;
        },
        locationUpdate : (state, action) => {
            state.successMsg = action.payload;
        },
        locationEditMsg : (state, action) => {
            state.locationEditMsg = action.payload;
          },           
        resetState : (state) => {
            state.successMsg = "";
            state.deleteSuccess = false;
            state.errorApiData = "";
            state.statusSuccess = false;
            state.createSuccessMsg = "";
            state.locationById = "";
        },
        setGlobalPage : (state, action)=>{
            state.globalPage = action.payload
          }
    },
});

// Action creators are generated for each case reducer function
export const {resetState, apiFailed,alllocationes,setGlobalPage,locationDetailData,createlocation,locationEditMsg,locationUpdate,setlocationDetail,locationDelete,deleteSuccess,statusLocation } = locationSlice.actions
export default locationSlice.reducer

export const getLocation = (page) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_DRIVERAPP_API_URL}/location/list?page=${page}`)
        dispatch(alllocationes(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}
export const addLocation = (values) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_DRIVERAPP_API_URL}/location/locationAdd`, values)
        dispatch(createlocation(res.data.message));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data.message))
        } else {
            dispatch(apiFailed(e.message))
        } 
    }
  }

export const deletelocation = (location_id) => async dispatch =>{
    try {
        const res = await Api('post',`${REACT_APP_DRIVERAPP_API_URL}/location/deletelocation`,{locationId : location_id})
        dispatch(locationDelete());
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
  }

export const editLocation = (location) => async dispatch => {
    try {

      //  ${company_id.get("_id")}`,{category_name : company_id.get('category_name') });
        const res = await Api('post', `${REACT_APP_DRIVERAPP_API_URL}/location/update`,{
            location_id:location.get('_id'),
            delivery_location:location.get('delivery_location'),
            slot_type:location.get('slot_type')            
        });
        dispatch(locationEditMsg(res.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}



export const detailLocation = (location_id) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_DRIVERAPP_API_URL}/location/detail`,{
            location_id:location_id
        });
        dispatch(setlocationDetail(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
  }

  export const statusDeliveryLocation = (statusData) => async dispatch => {  
    try{
        const res = await Api('post',`${REACT_APP_DRIVERAPP_API_URL}/location/status`,statusData);
        dispatch(statusLocation());
    }catch(e){
        if(e.response){
            dispatch(apiFailed(e.response.data.message));
        }else{
            dispatch(apiFailed(e.message));
        }
    }
  }

  export const clearData = () => dispatch => {
    dispatch(locationEditMsg(""));
  }
  
  export const setPageeeeee = (page) => dispatch => {
    dispatch(setGlobalPage(page))
  }