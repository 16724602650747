import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env

export const changePasswordSlice = createSlice({
    name: 'changePassword',
    initialState:{
        statusSuccess : '',
        errorApiData: '',
        errorOldPassword: '',
    },
    reducers: {
        apiFailed: (state, action) => {
            state.errorApiData = action.payload;
        },
        passwordUpdate: (state, action) => {
            state.statusSuccess = action.payload;
        },
        oldPasswordCheck: (state, action) => {
            state.errorOldPassword = action.payload;
        },
        resetState : (state) => {
            state.statusSuccess = "";
            state.errorApiData = "";
            state.errorOldPassword = "";
        },
    },
});

// Action creators are generated for each case reducer function
export const {apiFailed,resetState, passwordUpdate,oldPasswordCheck } = changePasswordSlice.actions
export default changePasswordSlice.reducer

export const updatePassword = (value) => async dispatch => {
    try{
        const res = await Api('post', `${REACT_APP_API_URL}/vendor/change-password`, value)
        dispatch(passwordUpdate(res.data.message))
    }catch(e){
        if (e.response) {
            dispatch(apiFailed(e.response.data.message))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const checkOldPasssword = (value) => async dispatch => {
    try{
        const res = await Api('post', `${REACT_APP_API_URL}/vendor/check-old-password`, value)
        console.log("res.data",res.data)
        if(res.data.statusCode == 200){
            dispatch(oldPasswordCheck(res.data.message))
        }else{
            dispatch(resetState())
        }
    }catch(e){
        if (e.response) {
            dispatch(apiFailed(e.response.data.message))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const clearMsg = () => dispatch => {
    dispatch(passwordUpdate(""));
}
  