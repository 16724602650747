import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
 const { REACT_APP_API_URL } = process.env

const initialState = {
  value: 0,
  user: {},
  viewwishlist:{},
  wishlists : [],
  statusMsg : "",
  userdeleteSuccess : false,
  errorMessage:"",
  successMsg:"",
  globalPage : 1
}

export const wishlistRequestSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    apiFailed: (state, action) => {
      state.errorApiData = action.payload;
    },
    allWishlistRequests : (state, action) => {
      state.wishlists = action.payload;
    },
    wishlistStatus : (state, action) => {
      state.successMsg = action.payload;
    },
    updateQuantityMsg : (state, action) => {
      state.statusMsg = action.payload;
    },
    wishlistDetail : (state, action) => {
      state.viewwishlist = action.payload;
    },
    setGlobalPage : (state, action)=>{
      state.globalPage = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const {wishlistStatus,wishlistDetail,apiFailed,allWishlistRequests,updateQuantityMsg,setGlobalPage } = wishlistRequestSlice.actions

export default wishlistRequestSlice.reducer

export const getWishlistRequests = (page,search) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/wishlist/wishlistRequests?search=${search}&page=${page}`);
      dispatch(allWishlistRequests(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }  
}

export const detailRequest = (id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/wishlist/viewWishlistRequest/${id}`);
      dispatch(wishlistDetail(res.data))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const updateQuantity = (formData) => async dispatch => {
  try{
      const res = await Api('post',`${REACT_APP_API_URL}/wishlist/updateQuantity`,formData);
      dispatch(updateQuantityMsg(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}

export const wishlistStatusChange = (statusData) => async dispatch => {
  try{
      const res = await Api('post',`${REACT_APP_API_URL}/wishlist/status`,{id:statusData});
      dispatch(wishlistStatus(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}

export const sendEmail = (declineData) => async dispatch => {
  try{
      const res = await Api('post',`${REACT_APP_API_URL}/wishlist/sendmail`,declineData);
      dispatch(wishlistStatus(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}

export const wishlistStatusCancel = (statusData) => async dispatch => {
  try{
    //console.log("statusData",statusData);
      const res = await Api('post',`${REACT_APP_API_URL}/wishlist/cancelStatus`,{id:statusData});
      dispatch(wishlistStatus(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}

export const clearData = () => dispatch => {
  dispatch(wishlistStatus(""));
}

export const setPageeeeee = (page) => dispatch => {
  dispatch(setGlobalPage(page))
}