import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
 const { REACT_APP_API_URL } = process.env

const initialState = {
  value: 0,
  user: {},
  driverAmount: {},
  viewexchangelist:{},
  collectcashlists : [],
  statusMsg : "",
  userdeleteSuccess : false,
  errorMessage:"",
  successMsg:"",
  globalPage : 1
}

export const collectcashRequestSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    apiFailed: (state, action) => {
      state.errorApiData = action.payload;
    },
    allCollectCashlistRequests : (state, action) => {
      state.collectcashlists = action.payload;
    },
    exchangelistStatus : (state, action) => {
      state.successMsg = action.payload;
    },
    updateQuantityMsg : (state, action) => {
      state.statusMsg = action.payload;
    },
    collectlistDetail : (state, action) => {
      state.viewreturnlist = action.payload;
    },
    setGlobalPage : (state, action)=>{
      state.globalPage = action.payload
    },
    allAssign : (state, action) => {
      state.drivers = action.payload;
    },
    driverAmount : (state, action) => {
      state.driverAmount = action.payload;
    },
    updateCash : (state, action) => {
      state.successMsg = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const {exchangelistStatus,collectlistDetail,apiFailed,allCollectCashlistRequests,updateQuantityMsg,setGlobalPage,allAssign,driverAmount,updateCash } = collectcashRequestSlice.actions

export default collectcashRequestSlice.reducer


export const getDriverList = () => async dispatch => {
  try {                  
      const res = await Api('post', `${REACT_APP_API_URL}/collectcash/get-driver`);
      console.log("===============RES=======",res.data.data);
      dispatch(allAssign(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const getAmount = (driver_id) => async dispatch => {  
  try{
      const res = await Api('post', `${REACT_APP_API_URL}/collectcash/getAmount/${driver_id}`);     
      dispatch(driverAmount(res.data))
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}

export const updateDriverCollectcash = (values) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/collectcash/addCollectCash`,values)
      dispatch(updateCash(res.data.message));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      } 
  }
}




export const getCollectCashRequests = (page,search) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/collectcash/cashtranlistRequests?search=${search}&page=${page}`);
      dispatch(allCollectCashlistRequests(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }  
}

export const detailRequest = (id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/collectcash/viewcollectcashlistRequest/${id}`);
      dispatch(collectlistDetail(res.data))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const sendEmail = (declineData) => async dispatch => {
  try{
      const res = await Api('post',`${REACT_APP_API_URL}/express/sendmail`,declineData);
      dispatch(exchangelistStatus(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}



export const updateQuantity = (formData) => async dispatch => {
  try{
      const res = await Api('post',`${REACT_APP_API_URL}/exchangelist/updateQuantity`,formData);
      dispatch(updateQuantityMsg(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}

export const exchangelistStatusChange = (statusData) => async dispatch => {
  try{
      const res = await Api('post',`${REACT_APP_API_URL}/exchangelist/status`,{id:statusData});
      dispatch(exchangelistStatus(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}



export const exchangelistStatusCancel = (statusData) => async dispatch => {
  try{
    //console.log("statusData",statusData);
      const res = await Api('post',`${REACT_APP_API_URL}/exchangelist/cancelStatus`,{id:statusData});
      dispatch(exchangelistStatus(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}

export const clearData = () => dispatch => {
  dispatch(exchangelistStatus(""));
}

export const setPageeeeee = (page) => dispatch => {
  dispatch(setGlobalPage(page))
}