import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
 const { REACT_APP_API_URL } = process.env

const initialState = {
  value: 0,
  user: {},
  viewreturnlist:{},
  returnlists : [],
  statusMsg : "",
  userdeleteSuccess : false,
  errorMessage:"",
  successMsg:"",
  globalPage : 1
}

export const returnRequestSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    apiFailed: (state, action) => {
      state.errorApiData = action.payload;
    },
    allReturnlistRequests : (state, action) => {
      state.returnlists = action.payload;
    },
    returnlistStatus : (state, action) => {
      state.successMsg = action.payload;
    },
    updateQuantityMsg : (state, action) => {
      state.statusMsg = action.payload;
    },
    returnlistDetail : (state, action) => {
      state.viewreturnlist = action.payload;
    },
    setGlobalPage : (state, action)=>{
      state.globalPage = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const {returnlistStatus,returnlistDetail,apiFailed,allReturnlistRequests,updateQuantityMsg,setGlobalPage } = returnRequestSlice.actions

export default returnRequestSlice.reducer

export const getReturnRequests = (page,search) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/return/returnlistRequests?search=${search}&page=${page}`);
      dispatch(allReturnlistRequests(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }  
}

export const detailRequest = (id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/return/viewreturnlistRequest/${id}`);
      dispatch(returnlistDetail(res.data))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const returnlistStatusChange = (statusData) => async dispatch => {
  try{
      const res = await Api('post',`${REACT_APP_API_URL}/return/returnstatus`,statusData);
      dispatch(returnlistStatus(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}

export const sendEmail = (declineData) => async dispatch => {
  try{
      const res = await Api('post',`${REACT_APP_API_URL}/return/sendmail`,declineData);
      dispatch(returnlistStatus(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}

export const updateQuantity = (formData) => async dispatch => {
  try{
      const res = await Api('post',`${REACT_APP_API_URL}/return/updateQuantity`,formData);
      dispatch(updateQuantityMsg(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}


/*

export const returnlistStatusCancel = (statusData) => async dispatch => {
  try{
    //console.log("statusData",statusData);
      const res = await Api('post',`${REACT_APP_API_URL}/returnlist/cancelStatus`,{id:statusData});
      dispatch(returnlistStatus(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
} */

export const clearData = () => dispatch => {
  dispatch(returnlistStatus(""));
}

export const setPageeeeee = (page) => dispatch => {
  dispatch(setGlobalPage(page))
}