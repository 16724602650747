import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
 const { REACT_APP_API_URL } = process.env

const initialState = {
  value: 0,
  user: {},
  vendorAmount: {},  
  vendorpaymentlists : [],
  vendorExportList : [],
  statusMsg : "",
  userdeleteSuccess : false,
  errorMessage:"",
  successMsg:"",
  globalPage : 1
}

export const vendorpaymentRequestSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    apiFailed: (state, action) => {
      state.errorApiData = action.payload;
    },
    allVendorPaymentlistRequests : (state, action) => {
      state.vendorpaymentlists = action.payload;
    },
    allVendorExcelRequest : (state, action) => {
      state.vendorExportList = action.payload;
    },    
    vendorpaymentlistDetail : (state, action) => {
      state.viewvendorpaymentlist = action.payload;
    },
    setGlobalPage : (state, action)=>{
      state.globalPage = action.payload
    },
    allAssign : (state, action) => {
      state.vendors = action.payload;
    },
    vendorAmount : (state, action) => {
      state.vendorPaymentAmount = action.payload;
    },
    updateVendorpayment : (state, action) => {
      state.successMsg = action.payload;
    },
    createVendorCommission : (state, action) => {
      state.successMsg = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const {allAssign,createVendorCommission,vendorAmount,updateVendorpayment,allVendorPaymentlistRequests,vendorpaymentlistDetail,apiFailed,setGlobalPage,allVendorExcelRequest} = vendorpaymentRequestSlice.actions

export default vendorpaymentRequestSlice.reducer


export const getVendorList = () => async dispatch => {
  try {                  
      const res = await Api('post', `${REACT_APP_API_URL}/vendorpayment/get-vendor`);
      console.log("===============RES=======",res.data.data);
      dispatch(allAssign(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const getVendorExcel = () => async dispatch => {
  try {                  
      const res = await Api('post', `${REACT_APP_API_URL}/vendorpayment/get-vendorExcel`);
      console.log("===============RES=======",res.data.data);
      dispatch(allVendorExcelRequest(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const getVendorAmount = (vendor_id) => async dispatch => {  
  try{
      const res = await Api('post', `${REACT_APP_API_URL}/vendorpayment/getVendorAmount/${vendor_id}`);     
      dispatch(vendorAmount(res.data))
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
}

export const updateVendorpaymentCash = (values) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/vendorpayment/addVendorpayment`,values)
      dispatch(updateVendorpayment(res.data.message));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      } 
  }
}

export const createCommission = (values) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/vendorpayment/createCommission`,values)
 
      dispatch(createVendorCommission(res.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      } 
  }
}

export const getvendorPaymentRequests = (page,search) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/vendorpayment/vendorpaymenttranlistRequests?search=${search}&page=${page}`);
      dispatch(allVendorPaymentlistRequests(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }  
}

export const detailRequest = (id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/vendorpayment/viewvendorpaymentlistRequest/${id}`);
      dispatch(vendorpaymentlistDetail(res.data))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

/* export const sendEmail = (declineData) => async dispatch => {
  try{
      const res = await Api('post',`${REACT_APP_API_URL}/express/sendmail`,declineData);
      dispatch(exchangelistStatus(res.data.message));
  }catch(e){
      if(e.response){
          dispatch(apiFailed(e.response.data.message));
      }else{
          dispatch(apiFailed(e.message));
      }
  }
} */
/* 
export const clearData = () => dispatch => {
  dispatch(exchangelistStatus(""));
} */

export const setPageeeeee = (page) => dispatch => {
  dispatch(setGlobalPage(page))
}