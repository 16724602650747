import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env

export const productSlice = createSlice({
    name: 'product',
    initialState:{
        products : [],
        brands : [],
        vendors : [],
        companies : [],
        categories : [],
        subCategories : [],
        variations : [],
        productlist : [],
        viewProduct:{},
        productEditMsg:"",
        errorApiData: '',
        errorMessage: '',
        successMsg: '',
        productId: '',
        successType: '',
        variationSuccessMsg: '',
        productCreateMsg: '',
        productById : {},
        productUpd : {},
        productDelete:'',
        uniqueSKUMsg:'',
        deleteSuccess : false,
        statusSuccess : false,
        globalPage : 1
    },
    reducers: {
        apiFailed: (state, action) => {
            state.errorApiData = action.payload;
        },
        allProductes : (state, action) => {
            state.products = action.payload;
        },
        allBrands : (state, action) => {
            state.brands = action.payload;
        },
        allVendors : (state, action) => {
            state.vendors = action.payload;
        },
        allCompanies : (state, action) => {
        state.companies = action.payload;
        },
        allCategories : (state, action) => {
        state.categories = action.payload;
        },
        allSubCategories : (state, action) => {
        state.subCategories = action.payload;
        },
        allVariations : (state, action) => {
            state.variations = action.payload;
        },
        createProduct : (state, action) => {
            state.productId = action.payload;
           // state.productCreateMsg = "Product created successfully.";
        },
        createProductVariation : (state, action) => {
            state.successType = action.payload;
        },
        productDetail : (state, action) => {
            state.viewProduct = action.payload;
        },
        productDelete : (state) => {
            state.deleteSuccess = true;
        },
        statusProduct: (state) => {
            state.statusSuccess = true;
        },
        productUpdate : (state, action) => {
            state.successMsg = action.payload;
        },
        productUpdateStatus : (state, action) => {
            state.successMsg = action.payload;
        },
        productVariationUpdate : (state, action) => {
            state.variationSuccessMsg = action.payload;
        },
        productEditMsg : (state, action) => {
            state.productEditMsg = action.payload;
        },
        checkUniqueSKU : (state, action) => {
            state.uniqueSKUMsg = action.payload;
        },
        resetState : (state) => {
            state.successMsg = "";
            state.deleteSuccess = false;
            state.errorApiData = "";
            state.statusSuccess = false;
            state.createSuccessMsg = "";
            state.productById = "";
            state.uniqueSKUMsg = "";
            state.productCreateMsg = "";
            state.productId = '';
        },
        setGlobalPage : (state, action)=>{
            state.globalPage = action.payload
        }
    },
});

// Action creators are generated for each case reducer function
export const {resetState, apiFailed,allProductes,allBrands,allVendors,allCompanies,allCategories,allSubCategories,allVariations,createProduct,productEditMsg,productUpdate,productDetail,productDelete,productVariationUpdate,productUpdateStatus,checkUniqueSKU,createProductVariation,setGlobalPage } = productSlice.actions
export default productSlice.reducer

export const getProduct = (page,search,filter = []) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/product/productList?page=${page}`,filter)
        dispatch(allProductes(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const getVariations = () => async dispatch => {
    try {           
        const res = await Api('post', `${REACT_APP_API_URL}/product/getAllVariations`);
        dispatch(allVariations(res.data.data));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const getVendors = () => async dispatch => {
    try {           
        const res = await Api('post', `${REACT_APP_API_URL}/product/getVendors`);
        dispatch(allVendors(res.data.data));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const getBrands = (vendor_id) => async dispatch => {
    try {           
        const res = await Api('post', `${REACT_APP_API_URL}/product/getBrands/${vendor_id}`);
        dispatch(allBrands(res.data.data));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const getCompanies = (vendor_id) => async dispatch => {
    try {           
        const res = await Api('post', `${REACT_APP_API_URL}/product/getListCompany/${vendor_id}`);
        dispatch(allCompanies(res.data.data[0].companies));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
  
    
}

export const getCategories = (product_id,page) => async dispatch => {
try {           
    const res = await Api('post', `${REACT_APP_API_URL}/product/getAllCategory/${product_id}`);
    dispatch(allCategories(res.data.data));
} catch (e) {
    if (e.response) {
        dispatch(apiFailed(e.response.data))
    } else {
        dispatch(apiFailed(e.message))
    }
}
}

export const getSubCategories = (parent,page) => async dispatch => {
try {       
    const res = await Api('post', `${REACT_APP_API_URL}/product/getAllSubCategory/${parent}`);
    dispatch(allSubCategories(res.data.data));
} catch (e) {
    if (e.response) {
        dispatch(apiFailed(e.response.data))
    } else {
        dispatch(apiFailed(e.message))
    }
}
}

export const addProduct = (values) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/product/addProduct`, values)
        dispatch(createProduct(res.data.data));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data.message))
        } else {
            dispatch(apiFailed(e.message))
        } 
    }
}

export const addProductVariation = (values) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/product/addProductVariation`, values)
        dispatch(createProductVariation(res.data.data));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data.message))
        } else {
            dispatch(apiFailed(e.message))
        } 
    }
}

export const editProduct = (product) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/product/editProduct`,{
            product_id:product.get('_id'),
            type:product.get('type'),
        });
        dispatch(productEditMsg(res.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const updateProduct = (value) => async dispatch => {
    try{
        const res = await Api('post', `${REACT_APP_API_URL}/product/updateProduct`, value)
        dispatch(productUpdate(res.data.message))
    }catch(e){
        if (e.response) {
            dispatch(apiFailed(e.response.data.message))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const updateProductVariation = (value) => async dispatch => {
    try{
        const res = await Api('post', `${REACT_APP_API_URL}/product/updateVariation`, value)
        dispatch(productVariationUpdate(res.data.message))
    }catch(e){
        if (e.response) {
            dispatch(apiFailed(e.response.data.message))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const detailProduct = (product_id) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/product/viewProduct/${product_id}`);
        dispatch(productDetail(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
} 

export const statusChanageProduct = (value) => async dispatch => {
    try{
        const res = await Api('post', `${REACT_APP_API_URL}/product/updateProductStatus`, value)
        dispatch(productUpdateStatus(res.data.message))
    }catch(e){
        if (e.response) {
            dispatch(apiFailed(e.response.data.message))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const statusBestSallerChange = (value) => async dispatch => {
    try{
        const res = await Api('post', `${REACT_APP_API_URL}/product/updateBestSaller`, value)
        dispatch(productUpdateStatus(res.data.message))
    }catch(e){
        if (e.response) {
            dispatch(apiFailed(e.response.data.message))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const checkSKU = (sku) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/product/checkSKU`, sku);
        if(res.data.status == 200){
            dispatch(checkUniqueSKU(res.data.message))
        }else{
            dispatch(resetState())
        }
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const clearData = () => dispatch => {
    dispatch(productEditMsg(""));
    dispatch(productVariationUpdate(""));
    dispatch(createProduct(""));
    dispatch(createProductVariation(""));
    dispatch(checkUniqueSKU(""));
}

export const setPageeeeee = (page) => dispatch => {
    dispatch(setGlobalPage(page))
}