import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env

const initialState = {
  value: 0,
  successMsg:"",
  settings : [],
  settingEditMsg : "",
}

export const settingSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    apiFailed: (state, action) => {
      state.errorApiData = action.payload;
    },
    allSettings : (state, action) => {
      state.settings = action.payload;
    },
    createSetting : (state, action) => {
      state.successMsg = action.payload;
    },
    settingEditMsg : (state, action) => {
      state.settingEditMsg = action.payload;
    },
    resetState : (state) => {
      state.successMsg = "";
      state.settingEditMsg = "";
      state.errorApiData = "";
      state.viewbrand = "";
    },
  },
})

// Action creators are generated for each case reducer function
export const { settingEditMsg,createSetting, apiFailed,allSettings,resetState } = settingSlice.actions

export default settingSlice.reducer

export const addSetting = (values) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/setting/addSetting`, values)
      dispatch(createSetting(res.data.message));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      } 
  }
}

export const getSettings = () => async dispatch => {
  try {           
      const res = await Api('post', `${REACT_APP_API_URL}/setting/getSettings`);
      
      dispatch(allSettings(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const updateSetting = (value) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/setting/updateSetting`,value)
      dispatch(settingEditMsg("Data updated successfully"))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const clearData = () => dispatch => {
  dispatch(settingEditMsg(""));
  dispatch(createSetting(""));
}