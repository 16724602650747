import React, { Component, Suspense, useEffect } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { USER_ROLE, USER_TOKEN } from "src/config";
import { useHistory, useLocation } from 'react-router-dom'

import "./scss/style.scss";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const ForgetPass = React.lazy(() => import("./views/pages/login/forgetPass"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
//const Invoice = React.lazy(()=> import('./views/invoice/invoice'))


if (localStorage.getItem(USER_ROLE) === "vendor") {
  var TheVendorLayout = React.lazy(() =>
    import("./vendor/containers/TheVendorLayout")
  );
} else {
  var TheLayout = React.lazy(() => import("./containers/TheLayout"));
}

const App = ({ history }) => {
  useEffect(() => {
    console.log(window.location.pathname);

    if (
      localStorage.getItem(USER_ROLE) === null &&
      localStorage.getItem(USER_TOKEN) === null
    ) {
      if(window.location.pathname=='forgetpass')
      {
        history.push("/forgetpass");
      }else{
        history.push("/login");        
      }
      
    }
  }, []);

  //render() {
  return (
    <Suspense fallback={loading}>
      <Switch>
        <Route
          exact
          path="/login"
          name="Login Page"
          render={(props) => <Login {...props} />}
        />
        <Route
          exact
          path="/register"
          name="Register Page"
          render={(props) => <Register {...props} />}
        />
         <Route
          exact
          path="/forgetpass"
          name="ForgetPass"
          render={(props) => <ForgetPass {...props} />}
        />
        <Route
          exact
          path="/404"
          name="Page 404"
          render={(props) => <Page404 {...props} />}
        />
        <Route
          exact
          path="/500"
          name="Page 500"
          render={(props) => <Page500 {...props} />}
        />

        {/* <Route
          exact
          path="/invoice"
          name="Invoice"
          render={(props) => <Invoice {...props} />}
        /> */}

        {localStorage.getItem(USER_ROLE) === "vendor" ? (
          <Route
            path="/"
            name="Vendor Dashboard"
            render={(props) => <TheVendorLayout {...props} />}
          />
        ) : (
          <Route
            path="/"
            name="Home"
            render={(props) => <TheLayout {...props} />}
          />
        )}

        <Route
          path="/orders"
          name="Orders"
          render={(props) => <TheLayout {...props} />}
        />
        {/* <Route path="/" name="Home" render={(props) => <TheLayout {...props} />} />
 
                      <Route path="/" name="Dashboard" render={(props) => <TheVendorLayout {...props} />} />
         */}
      </Switch>
    </Suspense>
  );
  //}
};

export default withRouter(App);
