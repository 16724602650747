import axios from 'axios'
import { USER_TOKEN } from '../config'

export const Api = async (method, url, data) => {
    const response = await axios({
        method: method,
        url: url,
        data: data,
        headers : {
            // "Content-Type": "multipart/form-data",
            "Content-Type": "application/json",
            'x-access-token' : localStorage.getItem(USER_TOKEN),
        }
    })
    return response
}
