import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/userSlice';
import driverReducer from './slices/driverSlice';
import driverRequestReducer from './slices/driverRequestSlice';
import locationReducer from './slices/locationSlice';
import scheduleReducer from './slices/scheduleSlice';
import companyReducer from './slices/companySlice';
import bannerReducer from './slices/bannerSlice';
import categoryReducer from './slices/categorySlice';
import subCategoryReducer from './slices/subCategorySlice';
import cmsReducer from './slices/cmsSlice';
import orderReducer from './slices/orderSlice';
import brandReducer from './slices/brandSlice';
import variationReducer from './slices/variationSlice';
import productReducer from './slices/productSlice';
import userKycRequestReducer from './slices/userKycRequestSlice';
import vendorReducer from './slices/vendorSlice';
import subadminReducer from './slices/subadminSlice';
import wishlistRequestReducer from './slices/wishlistRequestSlice';
import exchangeRequestReducer from './slices/exchangeRequestSlice';
import collectcashRequestReducer from './slices/collectcashRequestSlice';
import driverpaymentRequestReducer from './slices/driverpaymentRequestSlice';
import dashboardRequestReducer from './slices/dashboardRequestSlice';
import vendorpaymentRequestReducer from './slices/vendorpaymentRequestSlice';
import managenotificationRequestReducer from './slices/managenotificationRequestSlice';
import returnRequestReducer from './slices/returnRequestSlice';
import creditlistRequestReducer from './slices/creditlistRequestSlice';
import creditlistManagerReducer from './slices/creditlistManagerSlice';
import timeSlotReducer from './slices/timeSlotSlice';
import settingReducer from './slices/settingSlice';
import offerReducer from './slices/offerSlice';
import enquiryReducer from './slices/enquirySlice';
import couponCodeReducer from './slices/couponCodeSlice';
import walletReducer from './slices/walletSlice';
//Vendor module slices start
import changePasswordReducer from './vendor/slices/changePasswordSlice';
import vendorProductReducer from './vendor/slices/vendorProductSlice';
import editProfileReducer from './vendor/slices/editProfileSlice';
import vendorWishlistRequestReducer from './vendor/slices/vendorWishlistRequestSlice';
import reportReducer from './slices/reportSlice';

import salesPersonReducer from './slices/salesPersonSlice';
export const store = configureStore({
    reducer: {
        userReducer: userReducer,
        driverReducer: driverReducer,
        driverRequestReducer: driverRequestReducer,
        locationReducer: locationReducer,
        scheduleReducer: scheduleReducer,
        companyReducer: companyReducer,
        bannerReducer:bannerReducer,
        categoryReducer:categoryReducer,
        subCategoryReducer:subCategoryReducer,
        cmsReducer:cmsReducer,
        brandReducer:brandReducer,
        variationReducer:variationReducer,
        productReducer:productReducer,
        userKycRequestReducer:userKycRequestReducer,
        vendorReducer:vendorReducer,
        subadminReducer:subadminReducer,        
        wishlistRequestReducer:wishlistRequestReducer,
        exchangeRequestReducer:exchangeRequestReducer,
        collectcashRequestReducer:collectcashRequestReducer,
        dashboardRequestReducer:dashboardRequestReducer,
        driverpaymentRequestReducer:driverpaymentRequestReducer,
        vendorpaymentRequestReducer:vendorpaymentRequestReducer,
        managenotificationRequestReducer:managenotificationRequestReducer,        
        returnRequestReducer:returnRequestReducer,        
        creditlistRequestReducer:creditlistRequestReducer,
        creditlistManagerReducer:creditlistManagerReducer,
        changePasswordReducer:changePasswordReducer,
        vendorProductReducer:vendorProductReducer,
        editProfileReducer:editProfileReducer,
        timeSlotReducer:timeSlotReducer,
        settingReducer: settingReducer,
        orderReducer: orderReducer,
        vendorWishlistRequestReducer:vendorWishlistRequestReducer,
        offerReducer:offerReducer,
        enquiryReducer:enquiryReducer,
        couponCodeReducer:couponCodeReducer,
        walletReducer:walletReducer,
        reportReducer:reportReducer,
        salesPersonReducer:salesPersonReducer
    },
})