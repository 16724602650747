import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env

const initialState = {
  value: 0,
  category: {},
  viewcompany:{},
  categories : [],
  companyEdit:{},
  companyEditMsg : "",
  companydeleteSuccess : false,
}

export const categorySlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
    apiFailed: (state, action) => {
      state.errorApiData = action.payload;
    },
      allCategories : (state, action) => {
      state.categories = action.payload;
    },
    createCategory : (state, action) => {
      state.successMsg = action.payload;
    },
    categoryDetail : (state, action) => {
      state.viewcategory = action.payload;
    },
    categoryEdit : (state, action) => {
      state.categoryEdit = action.payload;
    },
    categoryEditMsg : (state, action) => {
      state.categoryEditMsg = action.payload;
    },
    categoryDelete : (state, action) => {
      state.successMsg = action.payload;
    },
    resetState : (state) => {
      state.successMsg = "";
    },
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement, categoryEditMsg,createCategory,categoryEdit,categoryDetail,incrementByAmount, categoryDelete, apiFailed,allCategories,resetState } = categorySlice.actions

export default categorySlice.reducer


export const getCategories = (company_id,page) => async dispatch => {
  try {           
      const res = await Api('post', `${REACT_APP_API_URL}/category/getAllCategory/${company_id}?page=${page}`);
      dispatch(allCategories(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}


export const addCategory = (values) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/category/getAddCategory`, values)
      dispatch(createCategory(res.data.message));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

// export const editCategory = (company_id) => async dispatch => {
//   try {
//       const res = await Api('post', `${REACT_APP_API_URL}/category/updateCustomer/${company_id}`)
//       dispatch(companyEdit(res.data))
//   } catch (e) {
//       if (e.response) {
//           dispatch(apiFailed(e.response.data))
//       } else {
//           dispatch(apiFailed(e.message))
//       }
//   }
// }

export const updateCategory = (company_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/category/getUpdateCategory/${company_id.get("_id")}`,{category_name : company_id.get('category_name') });
      dispatch(categoryEditMsg("Successfully update"))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const detailCategory = (category_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/category/getViewCategory/${category_id}`);
      dispatch(categoryDetail(res.data))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const deleteCategory = (category_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/category/getDeleteCategory`,{categoryId : category_id})
      dispatch(categoryDelete("Category deleted successfully."));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const clearData = () => dispatch => {
  dispatch(categoryEditMsg(""));
  dispatch(createCategory(""));
}