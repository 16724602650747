import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env

const initialState = {
  value: 0,
  subCategory: {},
  viewSubCategory:{},
  subCategories : [],
  subCategoryEdit:{},
  subCategoryEditMsg : "",
  subCategoryDeleteSuccess : false,
}

export const subCategorySlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
    apiFailed: (state, action) => {
      state.errorApiData = action.payload;
    },
    allSubCategories : (state, action) => {
      state.subCategories = action.payload;
    },
    createCategory : (state, action) => {
      state.successMsg = action.payload;
    },
    subCategoryDetail : (state, action) => {
      state.viewSubCategory = action.payload;
    },
    subCategoryEdit : (state, action) => {
      state.subCategoryEdit = action.payload;
    },
    subCategoryEditMsg : (state, action) => {
      state.subCategoryEditMsg = action.payload;
    },
    subCategoryDelete : (state) => {
      state.subCategoryDeleteSuccess = true;
  },
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement, subCategoryEditMsg,createCategory,subCategoryEdit,subCategoryDetail,incrementByAmount, subCategoryDelete, apiFailed,allSubCategories } = subCategorySlice.actions

export default subCategorySlice.reducer


export const getSubCategories = (parent,page) => async dispatch => {
  try {       
      const res = await Api('post', `${REACT_APP_API_URL}/category/getAllSubCategory/${parent}?page=${page}`);
      dispatch(allSubCategories(res.data.data));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const addCategory = (values) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/category/getAddSubCategory`, values)
      dispatch(createCategory(res.data.message));
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

// export const editCategory = (company_id) => async dispatch => {
//   try {
//       const res = await Api('post', `${REACT_APP_API_URL}/subCategory/updateCustomer/${company_id}`)
// const res = await Api('post', `${REACT_APP_API_URL}/category/getUpdateSubCategory/${category_id.get("_id")}`,{category_name : category_id.get('category_name') });
//       dispatch(companyEdit(res.data))
//   } catch (e) {
//       if (e.response) {
//           dispatch(apiFailed(e.response.data))
//       } else {
//           dispatch(apiFailed(e.message))
//       }
//   }
// }

export const updateCategory = (value) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/category/getUpdateSubCategory`,value)
      dispatch(subCategoryEditMsg("Sub category updated successfully."))
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data.message))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const deleteCategory = (category_id) => async dispatch => {
  try {
      const res = await Api('post', `${REACT_APP_API_URL}/category/getDeleteCategory`,{categoryId : category_id})
      dispatch(subCategoryDelete());
  } catch (e) {
      if (e.response) {
          dispatch(apiFailed(e.response.data))
      } else {
          dispatch(apiFailed(e.message))
      }
  }
}

export const clearData = () => dispatch => {
  dispatch(subCategoryEditMsg(""));
  dispatch(createCategory(""));
}